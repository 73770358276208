export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },

    '.smallNavMenu': {
      '> div': {
        border: 'none',
        '.navItem': {
          color: 'white',
          padding: '0.5rem',
          a: {
            fontFamily: 'Cinzel',
            fontSize: ['1rem', '1rem', '1rem', '0.9rem', '1.1rem'],
            fontWeight: 'bold',
            textShadow: '1px 1px 4px black',
            ':hover': {
              color: 'white',
              borderBottom: '1px solid',
              '::before': {}
            }
          }
        }
      }
    },
    '.navItemActive': {},
    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger > div': {
      backgroundColor: 'white'
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      // borderBottom: '1px solid',
      // borderColor: 'secondary',
      margin: '2.5rem 0rem auto',
      img: {
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '', '100px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['75%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem',
        display: 'none'
      },
      '.navItem': {
        padding: ['1rem'],
        flexGrow: 'unset',
        a: {
          fontSize: ['1.2rem', '1.35rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'secondary',
      padding: '1rem 3rem 0.5rem',
      margin: 'auto 0rem 0rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem']
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        // backgroundColor: '#c6a34d',
      }
    },
    '.logo': {
      padding: ['0.5rem', '', '0rem 0rem'],
      img: {
        opacity: '1',
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '80px']
      }
    },
    '.logoScrolled': {
      img: {
        filter: 'brightness(0) invert(1)',
        opacity: '1',
        maxHeight: ['45px', '55px']
      }
    },
    '.hamburger': {
      display: ['', '', '', 'none']
    }
  },

  footer: {
    // borderTop: '1px solid lightgrey',
    backgroundColor: 'black',
    '.socialContainer, .contactDetails-container': {
      svg: {
        marginRight: '1rem',
        path: {
          fill: 'grey'
        }
      }
    },
    '.logo': {
      filter: 'brightness(0) invert(1)'
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.gonationLogo': {
      filter: 'brightness(0) invert(1)',
      textAlign: 'center',
      margin: '0.5rem auto',
      span: {
        margin: '0 auto'
      }
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      textAlign: 'center',
      margin: '0.5rem auto'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary',
      fontSize: '1.25rem',
      fontFamily: 'IM Fell English SC',
      display: 'flex',
      svg: {
        order: '2',
        display: ['none', '', 'inline']
      },
      span: {
        display: 'none'
      },
      '::before': {
        // content: ["'Call Cast Iron Chef'", '', 'unset']
      }
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'normal',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '55%'],

      height: ['', '', '80vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        border: ['solid 6px', 'solid 6px', 'solid 10px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    paddingRight: ['', '', '1rem'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        // content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'white',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%'],
        border: ['solid 6px', 'solid 6px', 'solid 10px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%'],
        border: ['solid 6px', 'solid 6px', 'solid 10px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      }
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '50%'],
      maxWidth: 'unset',
      margin: '0rem auto',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['4rem', '4rem', '7rem', '10rem', '13rem'],
      textShadow: '2px 2px 10px black',
      color: 'light',
      fontFamily: 'IM Fell English SC'
    },
    '.subtitle': {
      fontFamily: 'Cinzel',
      fontSize: ['1.25rem', '1.5rem', '2.5rem', '3rem']
    },
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      fontSize: '1rem',
      backgroundColor: '#0000008c',
      padding: ['0.75rem 1rem', '', '1rem 4rem'],
      width: ['300px', '', 'auto']
    },
    '.linksContainer': {
      justifyContent: 'center'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    minHeight: '300px',
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '400px'],
      order: ['3', '', 'unset']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover'
    },
    '.content': {
      backgroundColor: ['', '', 'secondary'],
      color: 'white',
      margin: ['', '', '1rem 1rem'],
      width: ['100%', '100%', '60%'],
      border: 'solid 2px',
      borderColor: 'secondary',
      padding: ['', '', '0.5rem 5rem 0.5rem 2rem'],
      // backgroundImage:
      //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
      // backgroundColor: '#fff8f8',
      backgroundSize: 'auto',
      backgroundRepeat: 'repeat'
    },
    '.text': {
      lineHeight: '1.5',
      fontFamily: 'IM Fell English SC',
      color: ['text', '', 'light'],
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      letterSpacing: '1px',
      // fontFamily: 'heading',
      fontFamily: 'IM Fell English SC'
      // background: 'rgb(131,18,21)',

      // fontFamily: 'heading',
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: ['white', '', 'light'],
      letterSpacing: '3px',
      backgroundColor: 'black'
    },
    '.date': {
      color: ['text', '', 'light'],
      fontFamily: 'Cinzel'
      // display: 'none',
    },

    '.shoutCTA': {
      variant: 'buttons.primary',
      background: 'primary'
    }
  },

  homepageInstagram: {
    margin: '3rem 0rem',
    '.logoAndTextContainer': {
      display: 'none'
    },
    '.iFrameContainer': {
      width: '100%'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenus: {
    margin: ['1rem 0rem', '', '5rem 0rem'],
    maxWidth: 'unset',
    padding: ['3rem 1rem', '', '3rem 2rem'],
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',

    '.innerMenuContainer': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'Explore The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: '1rem 0.25rem 0rem'
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 1.5rem',
      fontSize: ['1.2rem', '1.3rem', '1.4rem', '1.5rem'],
      width: 'fit-content',
      borderBottom: 'solid 1px',
      borderColor: 'secondary'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      // opacity: '0.75',
      fontSize: '0.9rem',
      color: 'secondary'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      fontSize: ['1rem', '1rem', '1.1rem', '1.1rem']
    },
    '.menuItemDescription': {
      color: 'secondary'
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    variant: 'customVariants.sideBySide1'
  },

  homepageChef: {
    padding: ['15vh 1rem', '', '20vh 1rem'],
    margin: ['0rem', '', '5rem 0rem 0rem'],
    '.section': {
      width: '75%',
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem 10%'
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      border: 'none',
      textAlign: 'left',
      color: 'light',
      textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      fontFamily: 'display',
      borderTop: '8px double',
      borderColor: 'white',
      paddingTop: '1.5rem',
      marginBottom: '2rem'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      fontSize: '1.2rem'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    // minHeight: '100vh',
    '#menuTab': {
      fontFamily: 'IM Fell English SC',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    '.allInContainerWrapper > .allInContainer > .menuContainer > .menuSectionTitle ': {
      display: 'none'
    },
    '.innerMenuContainer': {
      padding: '1rem'
    },

    '.menuItemContainerImgActive, .menuItemContainer': {
      padding: '0.5rem'
    },

    '.menuItemName': {
      fontFamily: 'IM Fell English SC',
      marginBottom: '0rem',
      // fontFamily: 'heading',
      color: 'secondary',
      fontSize: ['1rem', '1rem', '1.1rem', '1.1rem'],
      svg: {
        color: 'primary',
        fill: 'primary'
      }
    },
    '.menuItemDescription': {
      color: 'black',
      fontFamily: 'Arial',
      // fontFamily: 'Montserrat',
      // fontFamily: 'heading',
      fontWeight: '500',
      opacity: '0.8'
    },
    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 1.5rem 1.5rem',
      fontSize: ['1.2rem', '1.3rem', '1.4rem', '1.5rem'],
      width: 'fit-content',
      borderBottom: 'solid 1px',
      borderColor: 'secondary',
      fontFamily: 'IM Fell English SC'

      // padding: '0rem 1rem',
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      // opacity: '0.75',
      fontSize: '0.9rem',
      color: 'secondary',
      margin: '1rem 1.5rem 1.5rem'
    },

    '.lightboxText': {
      backgroundColor: 'rgb(131 18 21 / 70%)',
      textTransform: 'uppercase',
      fontFamily: 'IM Fell English SC',
      fontSize: '1.75rem'
    },
    '.menuItemImg': {
      height: ['auto', '', '', '100%']
    }
  },

  // menu: {
  //   margin: '0rem 0rem',
  //   maxWidth: 'unset',
  //   padding: ['1rem', '', '1rem'],
  //   backgroundImage:
  //     'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
  //   backgroundColor: '#fff8f8',
  //   backgroundSize: 'auto',
  //   backgroundRepeat: 'repeat',
  //   '.backToMenuBtn': {
  //     variant: 'buttons.secondary',
  //     margin: '0rem auto 2rem 0rem',
  //   },
  //   '.dropdownContainer': {
  //     display: 'none',
  //   },
  //   '.section-cell': {
  //     width: ['100%', '', 'calc(50% - 1rem)'],
  //     borderRadius: '0px',
  //     position: 'relative',
  //   },
  //   '.allInContainerWrapper ': {
  //     maxWidth: '1200px',
  //     margin: '0rem auto',
  //   },
  //   '.cellImageContainer': {
  //     overflow: 'hidden',
  //   },
  //   '.cellImage': {
  //     borderRadius: '0px',
  //     transition: 'all ease-in-out 0.5s',
  //     ':hover': {
  //       transform: 'scale(1.1) translate(-50%, -50%)',
  //       transformOrigin: 'center center',
  //     },
  //   },
  //   '.menuCell': {
  //     borderRadius: '0px',
  //     '.imageFill': {
  //       paddingBottom: '60%',
  //     },
  //   },

  //   '.cellName': {
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     color: 'light',
  //     margin: '0rem',
  //     fontSize: ['1.5rem', '', '1.75rem', '2.5rem'],
  //   },

  //   '.tabsRow': {
  //     justifyContent: 'space-between',
  //     alignItems: 'flex-end',
  //     '::before': {
  //       variant: 'customVariants.title',
  //       fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
  //       content: "'Explore The Menu'",
  //       margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
  //       width: ['100%', '', '', 'fit-content'],
  //       letterSpacing: '0px',
  //       textTransform: 'capitalize',
  //       padding: '1rem 0rem',
  //     },
  //     '.menuTab': {
  //       fontSize: '0.9rem',
  //       border: 'none',
  //       textTransform: 'capitalize',
  //       padding: '0.25rem',
  //       letterSpacing: '-1px',
  //       fontFamily: 'heading',
  //       height: 'fit-content',
  //       fontWeight: '300',
  //       backgroundColor: 'transparent',
  //       margin: '1rem 0.25rem 0rem',
  //     },
  //     '.menuTabActive': {
  //       color: 'text',
  //       borderBottom: '1px solid black',
  //     },
  //   },

  //   '.menuContainer': {
  //     paddingBottom: '0rem',
  //   },

  //   '.menuSectionTitle': {
  //     textAlign: 'left',
  //     margin: '1rem 0rem 1.5rem',
  //     fontSize: ['1.2rem', '1.3rem', '1.4rem', '1.5rem'],
  //     width: 'fit-content',
  //     borderBottom: 'solid 1px',
  //     borderColor: 'secondary',
  //   },
  //   '.menuSectionDescription': {
  //     textAlign: 'left',
  //     // opacity: '0.75',
  //     fontSize: '0.9rem',
  //     color: 'secondary',
  //   },

  //   '.menuItemContainer, .menuItemContainerImgActive': {
  //     margin: '0rem 0rem 1rem',
  //     padding: ['0rem', '', '0rem 1.5rem 0rem 0rem'],
  //   },

  //   '.menuItemInnerContainer': {
  //     margin: '0rem',
  //   },
  //   '.menuItemContentContainer': {
  //     margin: '0rem',
  //   },

  //   '.menuItemName': {
  //     marginBottom: '0rem',
  //     fontFamily: 'heading',
  //     fontSize: ['1rem', '1rem', '1.1rem', '1.1rem'],
  //   },
  //   '.menuItemDescription': {
  //     color: 'secondary',
  //   },
  // },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    backgroundColor: '#202020',
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    },
    '.albumsCTAActive': {
      opacity: '1',
      textDecoration: 'none',
      '.albumTitle': {
        backgroundColor: 'white',
        color: 'secondary',
        border: 'solid 3px',
        borderColor: 'white',
        textDecoration: 'none'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    borderTop: 'solid 10px black',
    borderBottom: 'solid 10px black',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'light',
    '.content_container': {
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'secondary',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'light',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'light'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {
    padding: ['2rem 1rem', '2rem 1rem', '2rem 1rem', '2rem 1rem']
  },

  blogPage: {},
  contactBanner: {
    bg: 'dark',
    color: 'white'
  }
}
